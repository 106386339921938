/**
 * @param decodedToken Decoded JWT token object, received from Auth0
 * @param permissions One or more permissions that are required
 * @param requiresAll If true requires all permissions requested to be present
 */
export default function HasPermission(decodedToken: any, permissions: string[], requiresAll?: boolean): boolean {
	if (!decodedToken) return false;
	const userPermissions = decodedToken.permissions;

	if (!requiresAll) {
		return permissions.some((permission: string) => userPermissions.includes(permission));
	} else if (requiresAll) {
		return permissions.every((permission: string) => userPermissions.includes(permission));
	}

	return false;
}

export const Permissions = {
	// DEV_ADMIN: [
	// 	"read:hermes:articles",
	// 	"read:hermes:checkupresults",
	// 	"read:hermes:checkups",
	// 	"read:hermes:consumer",
	// 	"read:hermes:customer",
	// 	"read:hermes:department",
	// 	"read:hermes:impressions",
	// 	"read:hermes:order",
	// 	"read:hermes:printjobs",
	// 	"read:hermes:production",
	// 	"read:hermes:productionorder",
	// 	"read:hermes:products",
	// 	"read:hermes:revisions",
	// 	"read:hermes:tenant",
	// 	"read:tassel:assets",
	// 	"read:tassel:cosmetics",
	// 	"read:tassel:features",
	// 	"read:tassel:production",
	// 	"write:hermes:admin",
	// 	"write:hermes:checkupresults",
	// 	"write:hermes:checkups",
	// 	"write:hermes:consumer",
	// 	"write:hermes:customer",
	// 	"write:hermes:department",
	// 	"write:hermes:impressions",
	// 	"write:hermes:order",
	// 	"write:hermes:printjobs",
	// 	"write:hermes:production",
	// 	"write:hermes:productionorder",
	// 	"write:hermes:products",
	// 	"write:hermes:revisions",
	// 	"write:tassel:assets",
	// 	"write:tassel:cosmetics",
	// 	"write:tassel:features",
	// 	"write:tassel:production",
	// ],
	ADMIN: [
		"write:hermes:revisions",
		"write:hermes:products",
		"write:hermes:productionorder",
		"write:hermes:printjobs",
		"write:hermes:order",
		"write:hermes:impressions",
		"write:hermes:department",
		"write:hermes:customer",
		"write:hermes:consumer",
		"write:hermes:checkups",
		"write:hermes:checkupresults",
		"read:hermes:tenant",
		"read:hermes:revisions",
		"read:hermes:products",
		"read:hermes:productionorder",
		"read:hermes:printjobs",
		"read:hermes:order",
		"read:hermes:impressions",
		"read:hermes:department",
		"read:hermes:customer",
		"read:hermes:consumer",
		"read:hermes:checkups",
		"read:hermes:checkupresults",
		"read:hermes:articles",
	],

	BRANDCONFIG_ADMIN: [
		"read:tassel:assets",
		"read:tassel:cosmetics",
		"read:tassel:features",
		"read:tassel:production",
		"write:tassel:assets",
		"write:tassel:cosmetics",
		"write:tassel:features",
		"write:tassel:production",
	],

	BRANDCONFIG_DESIGNER: ["read:tassel:assets", "read:tassel:cosmetics", "write:tassel:assets", "write:tassel:cosmetics"],

	PRODUCTION_ADMIN: [
		"write:hermes:revisions",
		"write:hermes:production",
		"write:hermes:printjobs",
		"write:hermes:impressions",
		"write:hermes:order",
		"read:hermes:consumer",
		"read:hermes:customer",
		"read:hermes:department",
		"read:hermes:impressions",
		"read:hermes:order",
		"read:hermes:printjobs",
		"read:hermes:production",
		"read:hermes:productionorder",
		"read:hermes:revisions",
		"read:hermes:tenant",
	],

	PRODUCTION_USER: [
		"write:hermes:revisions",
		"write:hermes:production",
		"write:hermes:printjobs",
		"write:hermes:impressions",
		"read:hermes:consumer",
		"read:hermes:customer",
		"read:hermes:department",
		"read:hermes:impressions",
		"read:hermes:order",
		"read:hermes:printjobs",
		"read:hermes:production",
		"read:hermes:productionorder",
		"read:hermes:revisions",
		"read:hermes:tenant",
	],

	SALES: [
		"read:hermes:revisions",
		"write:hermes:products",
		"write:hermes:productionorder",
		"write:hermes:order",
		"write:hermes:impressions",
		"write:hermes:department",
		"write:hermes:customer",
		"write:hermes:consumer",
		"write:hermes:checkups",
		"write:hermes:checkupresults",
		"read:hermes:tenant",
		"read:hermes:products",
		"read:hermes:productionorder",
		"read:hermes:order",
		"read:hermes:impressions",
		"read:hermes:department",
		"read:hermes:customer",
		"read:hermes:consumer",
		"read:hermes:checkups",
		"read:hermes:checkupresults",
		"read:hermes:articles",
	],
};
