import { useAuth0 } from "@auth0/auth0-react"
import React from "react";
import { Button, Container, Image, Nav, Navbar, NavDropdown, Spinner } from "react-bootstrap";
import * as Icon from "react-bootstrap-icons";
import { useNavigate } from "react-router";

export default function Navigation() {
	//check user permissions/role and adjust the navbar accordingly.
	//also keep checks on each page to check if user has proper permission/role.
	const { user, isAuthenticated, logout, isLoading } = useAuth0();
	const redirect = process.env.NODE_ENV === "development" ? process.env.REACT_APP_AUTH0_DEV_REDIRECT_URL : process.env.REACT_APP_AUTH0_REDIRECT_URL;
	const navigate = useNavigate();

	const signout = () => {
		localStorage.removeItem("customerID");
		localStorage.removeItem("accessToken");
		localStorage.removeItem("tenant");
		localStorage.removeItem("tenant_name");

		logout({
			logoutParams: {
				returnTo: redirect,
			},
		});
	};

	const tenantName = localStorage.getItem("tenant_name");

	function isTTTT() {
		if (process.env.REACT_APP_BRANDCONFIG_BASEURL === "https://app.dopple.dev") return "tttt";
		else return "";
	}
	return (
		<>
			<Navbar
				fixed="top"
				expand="lg"
				className={`${isTTTT()} navbar-light `}
				bg="light"
				data-bs-theme="light"
			>
				<Container>
					<Navbar.Brand
						onClick={() => {
							navigate("/");
							if (process.env.NODE_ENV === "development") {
								var token = localStorage.getItem("accessToken");
								if (token !== null) console.log(JSON.parse(token));
							}
						}}
					>
						<Image
							src="\EarsOnly_Logo_FullColour_Grey.svg"
							alt="logo"
							height={40}
							style={{ marginRight: 5 }}
						></Image>
						Tenant Portal
					</Navbar.Brand>
					{isAuthenticated && (
						<>
							<Navbar.Toggle aria-controls="basic-navbar-nav" />
							<Navbar.Collapse id="basic-navbar-nav">
								<Nav className="me-auto">
									<Nav.Link onClick={() => navigate("/home")}>Home</Nav.Link>

									<NavDropdown
									className="bg-light"
										title="More"
										id="basic-nav-dropdown"
									>
										<NavDropdown.Item onClick={() => navigate("/orders")}> Orders</NavDropdown.Item>
										<NavDropdown.Item onClick={() => navigate("/companies")} >Customers</NavDropdown.Item>
										<NavDropdown.Item onClick={() => navigate("/appointments")} >Appointments</NavDropdown.Item>
										<NavDropdown.Divider />
										<NavDropdown.Item onClick={() => navigate("/scanandmodel")} >Scan and Model</NavDropdown.Item>
										<NavDropdown.Item onClick={() => navigate("/production")} >Production</NavDropdown.Item>
										<NavDropdown.Item onClick={() => navigate("/printbatches")} >Print batches</NavDropdown.Item>
										<NavDropdown.Item onClick={() => navigate("/shipping")} >Shipping</NavDropdown.Item>
										<NavDropdown.Divider />
										<NavDropdown.Item onClick={() => navigate("/search")} >Search</NavDropdown.Item>
										<NavDropdown.Item onClick={() => navigate("/adminpanel")} >Admin panel</NavDropdown.Item>
									</NavDropdown>
								</Nav>
							</Navbar.Collapse>
							<Navbar.Text>
								Signed in: {user?.name} <small> [{tenantName}]</small>
							</Navbar.Text>
							<Button
								variant="outline-danger"
								className="signbutton signout-button fw-bold rounded-pill "
								type="button"
								onClick={signout}
								title="sign out"
							>
								{" "}
								<Icon.BoxArrowLeft />
							</Button>
						</>
					)}
					{!isAuthenticated && !isLoading && (
						<Container
							fluid
							className="center"
						>
							<Navbar.Text>Not signed in</Navbar.Text>
						</Container>
					)}
				</Container>
			</Navbar>
			{isLoading && (
				<div className="center">
					<p>Loading...</p>
					<Spinner
						animation="border"
						role="status"
					></Spinner>
				</div>
			)}
		</>
	);
}
