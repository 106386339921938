import { useAuth0 } from "@auth0/auth0-react";
import * as React from "react";
import { useEffect, useState } from "react";
import { Row, Col, ListGroup, Form, InputGroup, FormGroup } from "react-bootstrap";
import { ListItem } from "../api/types/ListItem";
import Select from "react-select";
import { ProductDefinitions, ProductType } from "../api/types/ProductType";

interface Props {
	index: number;
	productsList: ListItem[];
	consumer: ListItem;
	productDefinitions: ProductDefinitions | undefined;
}

export default function OrderProduct(props: Props) {
	const [selectedProduct, setSelectedProduct] = useState<ProductType>();
	const [selectedProductFromList, setSelectedProductFromList] = useState<ListItem>();
	const [assemblyOptionsList, setAssemblyOptionsList] = useState<ListItem[]>([]);
	function handleProductSelected(selected: ListItem) {
		console.log('selected', selected);
		setSelectedProductFromList(selected);
		setSelectedProduct(props.productDefinitions?.products.find((e) => e.id === selected.value));
	}
	useEffect(
		function PopulateAssemblyOptions() {
			var temp: ListItem[] = [];
			selectedProduct?.assembly_values.forEach((assembly) => {
				temp.push({ label: assembly.name, value: assembly.code });
			});
			setAssemblyOptionsList(temp);
		},
		[selectedProduct?.assembly_values]
	);

	return (
		<>
			<ListGroup.Item
				className="product-selection"
				key={props.index}
				variant={props.index % 2 === 0 ? "none" : "secondary"}
			>
				<h5 className="name">{props.consumer.label}</h5>
				<hr />
				{/* <Row>
						<Col sm={4}>
							<Form.Label>Select a preset:</Form.Label>
							<Select
								isDisabled
								options={[
									{ value: "hdsahfoihfoi", label: "Motor rood" },
									{ value: "hdsahfoihf543", label: "Festival groen" },
								]}
							/>
						</Col>
					</Row>
					<hr /> */}
				<FormGroup >
					<Row>
						<Col sm={2}>
							<Col
								sm={2}
								className="fw-bold"
							>
								Product
							</Col>
							<Select
								value={selectedProductFromList}
								id={props.consumer.value}
								instanceId={props.consumer.value}
								inputId={props.consumer.value}
								options={props.productsList}
								name={`${props.consumer.value}-product`}
								onChange={(selected) => handleProductSelected({ label: selected?.label ?? "", value: selected?.value ?? "" })}
							/>
						</Col>
						<Col sm={2}>
							<Form.Label className="fw-bold">{selectedProduct?.assembly_label}</Form.Label>
							{selectedProduct?.assembly_values.map((assembly, index) => (
								<>
									<Form.Check
										defaultChecked={index === 0}
										type="radio"
										name={`${props.consumer.value}-assembly`}
										label={assembly.name}
										value={assembly.code}
										id={`${props.consumer.value}-${assembly.code}`}
										key={assembly.code}
									></Form.Check>
								</>
							))}
						</Col>
						<Col
							sm={2}
							className="border-right"
						>
							<Form.Label className="fw-bold">{selectedProduct?.variant_label}</Form.Label>
							{selectedProduct?.variant_values.map((variant, index) => (
								<>
									<Form.Check
										defaultChecked={index === 0}
										type="radio"
										name={`${props.consumer.value}-variant`}
										
										label={variant.name}
										value={variant.code}
										id={`${props.consumer.value}-${variant.code}`}
										key={variant.code}
									></Form.Check>
								</>
							))}
						</Col>
						<Col className="border-right">
							{selectedProduct?.required_acc.map((required) => (
								<>
									<Form.Label className="fw-bold">{required.title}</Form.Label>
									{required.values.map((accessory) => (
										<InputGroup>
											<Col>
												<Form.Check
													type={required.allow_multiple ? "checkbox" : "radio"}
													label={accessory.name}
													value={accessory.exact_id}
													name={`${props.consumer.value}-${required.reference_id}`}
													id={`${props.consumer.value}-${accessory.exact_id}`}

													key={accessory.exact_id}
												></Form.Check>
											</Col>
											{required.allow_quantity && (
												<Col sm={4}>
													<Form.Control
														type="number"
														size="sm"
														name={`${required.reference_id}-amount`}
													></Form.Control>
												</Col>
											)}
										</InputGroup>
									))}
								</>
							))}
						</Col>
						{selectedProduct?.optional_acc.length !== 0 && (
							<Col>
								{selectedProduct?.optional_acc.map((optional) => (
									<>
										<Form.Label className="fw-bold">{optional.title}</Form.Label>
										{optional.values.map((accessory) => (
											<InputGroup>
												<Col>
													<Form.Check
														type={optional.allow_multiple ? "checkbox" : "radio"}
														label={accessory.name}
														value={accessory.exact_id}
														name={`${props.consumer.value}-${optional.reference_id}`}
														id={`${props.consumer.value}-${accessory.exact_id}`}
														key={accessory.exact_id}
													></Form.Check>
												</Col>
												<Col sm={4}>
													{optional.allow_quantity && (
														<Form.Control
															type="number"
															size="sm"
															name={`${optional.reference_id}-amount`}
														></Form.Control>
													)}
												</Col>
											</InputGroup>
										))}
									</>
								))}
							</Col>
						)}
					</Row>
				</FormGroup>
			</ListGroup.Item>
		</>
	);
}
