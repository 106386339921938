import { Text, Document, Image, Page, StyleSheet, View } from "@react-pdf/renderer";
import React from "react";
import { toCanvas } from "qrcode";

const QRCodeWrapper = (props: { code: string }) => {
	let canvas = document.createElement("canvas");
	toCanvas(canvas, props.code);
	const barcode = canvas.toDataURL();
	return (
		<Image
			style={{ width: 50 }}
			src={barcode}
		></Image>
	);
};
interface Props {
	barcodes: string[];
}

export const CreateBagCodeSheet = (props: Props) => {
	const style = StyleSheet.create({
		row: {
			flexDirection: "row",
		},
		col: {
			flexDirection: "column",
			paddingRight: "100px",
		},
		page: {
			justifyContent: "space-evenly",
			paddingLeft: "25px",
			paddingRight: "15px",
			paddingTop: "25px",
			paddingBottom: "25px",
			height: "100vh",
		},
		textTop: {
			position: "absolute",
			left: 50,
			top: 10,
			width: 75,
			fontSize: "15px",
			fontWeight: "extrabold",
		},
		textBottom: {
			position: "absolute",
			left: 50,
			top: 23,
			width: 75,
			fontSize: "15px",
			fontWeight: "extrabold",
		},
	});

	function GenerateRows() {
		let rows = [];
		if (props.barcodes)
			for (let i = 0; i < props.barcodes.length; i += 4) {
				rows.push(
					<View
						style={style.row}
						key={props.barcodes[i]}
						break={i % 52 === 0 && i !== 0}
					>
						<View style={style.col}>
							<QRCodeWrapper code={props.barcodes[i]} />
							<Text style={style.textTop}>{props.barcodes[i].split("-")[0]}</Text>
							<Text style={style.textBottom}>{props.barcodes[i].split("-")[1]}</Text>
						</View>
						<View style={style.col}>
							<QRCodeWrapper code={props.barcodes[i + 1]} />
							<Text style={style.textTop}>{props.barcodes[i + 1].split("-")[0]}</Text>
							<Text style={style.textBottom}>{props.barcodes[i + 1].split("-")[1]}</Text>
						</View>
						<View style={style.col}>
							<QRCodeWrapper code={props.barcodes[i + 2]} />
							<Text style={style.textTop}>{props.barcodes[i + 2].split("-")[0]}</Text>
							<Text style={style.textBottom}>{props.barcodes[i + 2].split("-")[1]}</Text>
						</View>
						<View style={style.col}>
							<QRCodeWrapper code={props.barcodes[i + 3]} />
							<Text style={style.textTop}>{props.barcodes[i + 3].split("-")[0]}</Text>
							<Text style={style.textBottom}>{props.barcodes[i + 3].split("-")[1]}</Text>
						</View>
					</View>
				);
			}
		return <>{rows}</>;
	}

	return (
		<>
			<Document>
				<Page style={style.page}>
					<GenerateRows />
				</Page>
				-
			</Document>
		</>
	);
};
