import { Document, Image, Page, StyleSheet, Text, View } from "@react-pdf/renderer";
import QRCode from "qrcode";
import * as React from "react";
import { Customer } from "../../api/types/Customer";
import { inShipping } from "../../api/types/Shipment";
import { Tenant } from "../../api/types/Tenant";
import Logo from "./EarsOnly_Logo_FullColour_Grey.png";

interface pakbonProps {
	tenant: Tenant;
	shipment: inShipping;
	customers: Customer[];
}

export const Pakbon = (props: pakbonProps) => {
	return (
		<Document>
			<Page>
				<DoppleInfo />
				<TenantInfo {...props.tenant} />
				<PakbonInfo {...props.shipment} />
				<ShipmentOrders
					shipment={props.shipment}
					customers={props.customers}
				/>
				<Text
					style={styles.pageNumber}
					render={({ pageNumber, totalPages }) => `${pageNumber} / ${totalPages}`}
					fixed
				/>
			</Page>
		</Document>
	);
};

const styles = StyleSheet.create({
	pageNumber: {
		textAlign: "center",
		fontSize: 10,
		position: "absolute",
		bottom: 30,
		right: 30,
	},
});

interface orderProps {
	shipment: inShipping;
	customers: Customer[];
}

function ShipmentOrders(props: orderProps) {
	const style = StyleSheet.create({
		header: {
			paddingTop: 50,
			flexDirection: "row",
			fontSize: 10,
			fontFamily: "Helvetica-Bold",
			paddingLeft: 20,
			paddingRight: 20,
			fontWeight: "bold"
		},
		line: {
			borderBottom: "1px solid black",
			width: 550,
			left: 20,
			marginBottom: 10,
		},
		headerText: {
			width: 150,
		},
		headerText_small: {
			width: 100,
		},
		headerText1: {
			width: 30,
		},
		orderInfo: {
			fontSize: 10,
			flexDirection: "row",
			paddingLeft: 20,
			paddingBottom: 5,
			paddingRight: 20,
		},
		order: {
			width: 150,
		},
		order_small: {
			width: 100,
		},
		orderAmount: {
			width: 30,
		},
	});

	var orders: any = [];
	props.shipment.items.forEach((item, index) => {
		var customerName = props.customers.find((e) => e.id === item.customer_id)?.name;
			orders.push(
				<View
					key={item.chisel_pr_id}
					style={style.orderInfo}
				>
					<Text style={style.orderAmount}>{index}</Text>
					<Text style={style.order}>{item.chisel_pr}</Text>
					<Text style={style.order}>{item.consumer}</Text>
					<Text style={style.order}>{customerName}</Text>
					<Text style={style.order_small}>{item.exact_order_number}</Text>
					<Text style={style.order_small}>{item.referenceId}</Text>
				</View>
			);
		
	});

	return (
		<>
			<View style={style.header}>
				<Text style={style.headerText1}>#</Text>
				<Text style={style.headerText}>PR-nummer</Text>
				<Text style={style.headerText}>Gebruiker</Text>
				<Text style={style.headerText}>Klantnaam</Text>
				<Text style={style.headerText_small}>Exact nr.</Text>
				<Text style={style.headerText_small}>Referentie</Text>
			</View>
			<View style={style.line}></View>
			{orders}
		</>
	);
}


function PakbonInfo(shipment: inShipping) {
	const style = StyleSheet.create({
		qrcode: {
			position: "absolute",
			width: 50,
			height: 50,
			top: 50,
			left: 100,
		},
		title: {
			paddingTop: 15,
			fontFamily: "Helvetica-Bold",
			fontSize: 20,
			textAlign: "right",
		},
		titleLine: {
			width: "85vw",
			left: 50,
			borderBottom: "1px solid black",
		},
		header: {
			width: 200,
			paddingTop: 15,
			fontSize: 10,
			fontFamily: "Helvetica-Bold",
		},
		headerLine: {
			left: 190,
			borderBottom: "1px solid black",
			width: 310,
		},
		info: {
			paddingTop: 5,
			width: 200,
			fontFamily: "Helvetica",
			fontSize: 10,
		},
		row: {
			flexDirection: "row",
			justifyContent: "flex-end",
		},
	});

	const url = QRCode.toDataURL(shipment.shippingID);
	return (
		<View>
			<View style={style.titleLine}>
				<Text style={style.title}>Pakbon</Text>
			</View>
			<View style={style.row}>
				<Text style={style.header}>Pakbon ID</Text>
				<Text style={style.header}>Track & trace code</Text>
			</View>
			<View style={style.headerLine}></View>
			<Image
				style={style.qrcode}
				source={url}
			/>
			<View style={style.row}>
				<Text style={style.info}>{shipment.shippingID}</Text>
				<Text style={style.info}>{shipment.items[0].trackingData}</Text>
			</View>
		</View>
	);
}

function TenantInfo(tenant: Tenant) {
	const style = StyleSheet.create({
		company: {
			left: 80,
			fontSize: 12,
			fontFamily: "Helvetica-Bold",
		},
		address: {
			left: 80,
			fontFamily: "Helvetica",
			fontSize: 12,
		},
	});

	var address = tenant.address.split(",", 2);
	return (
		<>
			<View>
				<Text style={style.company}>{tenant.name}</Text>
				<Text style={style.address}>{address[0]}</Text>
				<Text style={style.address}>{address[1]}</Text>
			</View>
		</>
	);
}

function DoppleInfo() {
	const style = StyleSheet.create({
		logo: {
			width: 350,
		},
		address_info: {
			position: "absolute",
			right: 50,
			top: 30,
			fontFamily: "Helvetica",
		},
		dopple: {
			fontFamily: "Helvetica-Bold",
			fontSize: 8,
		},
		address: {
			fontSize: 8,
		},
		dopple_info: {
			position: "absolute",
			right: 31,
			top: 75,
			fontFamily: "Helvetica",
		},
		row: {
			flexDirection: "row",
			justifyContent: "flex-start",
			fontSize: 8,
			fontWeight: "bold",
		},
		label: {
			width: 60,
			textAlign: "right",
		},
		info: {
			textAlign: "left",
			paddingLeft: 8,
		},
	});
	return (
		<>
			<Image
				src={Logo}
				style={style.logo}
			/>
			<View style={style.address_info}>
				<Text style={style.dopple}>Dopple B.V.</Text>
				<Text style={style.address}>Overcingellaan 7</Text>
				<Text style={style.address}>9401 LA ASSEN</Text>
				<Text style={style.address}>Nederland</Text>
			</View>
			<View style={style.dopple_info}>
				<View style={style.row}>
					<Text style={style.label}>E-mail</Text>
					<Text style={style.info}>info@earsonly.nl</Text>
				</View>
				<View style={style.row}>
					<Text style={style.label}>Website</Text>
					<Text style={style.info}>www.earsonly.nl</Text>
				</View>
				<View style={style.row}>
					<Text style={style.label}>Bank</Text>
					<Text style={style.info}>037.25.69.994</Text>
				</View>
				<View style={style.row}>
					<Text style={style.label}>IBAN</Text>
					<Text style={style.info}>NL42RABO03725699</Text>
				</View>
				<View style={style.row}>
					<Text style={style.label}>BIC</Text>
					<Text style={style.info}>RABONL2U</Text>
				</View>
				<View style={style.row}>
					<Text style={style.label}>KvK</Text>
					<Text style={style.info}>68318456</Text>
				</View>
				<View style={style.row}>
					<Text style={style.label}>BTW nr.</Text>
					<Text style={style.info}>NL857389828B01</Text>
				</View>
			</View>
		</>
	);
}
