import { useAuth0 } from "@auth0/auth0-react";
import React, { useEffect, useState } from "react";
import { Button, Card, Col, Container, Image, Row, Spinner } from "react-bootstrap";
import * as Icon from "react-bootstrap-icons";
import { useJwt } from "react-jwt";
import { useNavigate } from "react-router";
import Select from "react-select";
import { getBagCodes, getTenants } from "../api/orderportal_apimanager";
import { ListItem } from "../api/types/ListItem";
import { Tenant } from "../api/types/Tenant";
import Navigation from "../modules/navigation";
import HasPermission, { Permissions } from "../modules/permissionCheck";
import { CreateBagCodeSheet } from "../modules/pdf/createBagCodeSheet";
import { PDFDownloadLink } from "@react-pdf/renderer";

export default function LandingPage() {
	const { user, isAuthenticated, isLoading, loginWithRedirect, getAccessTokenSilently } = useAuth0();
	const navigate = useNavigate();

	const login = async () => {
		await loginWithRedirect({
			authorizationParams: { prompt: "select_account" },
		});
	};

	const [selectedTenant, setSelectedTenant] = useState<string>("");

	const [tenants, setTenants] = useState<ListItem[]>();

	const [token, setToken] = useState<string>("");
	const customerID = localStorage.getItem("customerID");
	const tenantID = localStorage.getItem("tenant");
	const [barcodesFromAPI, setBarcodesFromAPI] = useState<string[]>([]);

	const { decodedToken } = useJwt<any>(token!);

	useEffect(
		function RetrieveTenantsFromDecodedToken() {
			if (decodedToken) {
				var temp: ListItem[] = [];
				getTenants(token).then((response) => {
					if (response)
						decodedToken["https://login.dopple.site/tenant"].forEach((auth0tenant: string) => {
							response.forEach((tenant: Tenant) => {
								if (auth0tenant === tenant.id) {
									temp.push({
										label: tenant.name,
										value: tenant.id,
									});
								}
							});
						});
					setTenants(temp);
					var T = temp[0];
					if (tenantID) {
						temp.forEach((element) => {
							if (element.value === tenantID) T = element;
						});
					}
					if (T) {
						setSelectedTenant(T.value);
						localStorage.setItem("tenant", T.value);
						localStorage.setItem("tenant_name", T.label);
					}
				});
			}
		},
		[decodedToken, tenantID, token]
	);

	function handleTenantChange(event: any) {
		localStorage.setItem("customerID", "");
		setSelectedTenant(event.value);
		localStorage.setItem("tenant", event.value);
		localStorage.setItem("tenant_name", event.label);
	}

	useEffect(
		function getBarcodesFromApi() {
			if (tenantID && token) getBagCodes(token, tenantID, 2).then((response) => setBarcodesFromAPI(response));
		},
		[tenantID, token]
	);

	useEffect(
		function RetrieveAccessTokenAndStoreInStorage() {
			if (isAuthenticated) {
				const getAccessToken = async () => {
					const temp = await getAccessTokenSilently();
					setToken(temp);
					localStorage.setItem("accessToken", JSON.stringify(token));
				};
				getAccessToken();
			}
		},
		[getAccessTokenSilently, isAuthenticated, token]
	);

	return (
		<>
			<Navigation />

			{isAuthenticated && (
				<>
					<Container className="paddingTopBottom">
						<Row>
							<Col>
								<p>Welcome, {user?.name}!</p>
							</Col>
							{tenants && tenants[0] && tenants.length !== 1 && (
								<>
									<Col
										className="align-selectors"
										sm={2}
									>
										<Select
											options={tenants}
											placeholder={localStorage.getItem("tenant_name")}
											onChange={handleTenantChange}
										/>
									</Col>
								</>
							)}
						</Row>
						<h1> {tenants?.find((e) => e.value === selectedTenant)?.label} </h1>

						<br />
						<Row className="gy-4 ">
							<>
								{(HasPermission(decodedToken, Permissions.ADMIN, true) || HasPermission(decodedToken, Permissions.SALES, true)) && (
									<>
										<Col sm={6}>
											<Card
												border="none"
												onClick={() => navigate("/orders")}
											>
												<Card.Header>
													<p>
														{" "}
														<Icon.Receipt />
														<br />
														Orders
													</p>
												</Card.Header>
											</Card>
										</Col>
										<Col sm={6}>
											<Card
												border="none"
												onClick={() => navigate("/companies")}
											>
												<Card.Header>
													<p>
														{" "}
														<Icon.BuildingFill />
														<br /> Customers
													</p>
												</Card.Header>
											</Card>
										</Col>
										<Col sm={6}>
											<Card
												border="none"
												onClick={() => navigate("/appointments")}
											>
												<Card.Header>
													<p>
														{" "}
														<Icon.CalendarEventFill />
														<br /> Appointments
													</p>
												</Card.Header>
											</Card>
										</Col>
										<Col sm={6}>
											<Card
												border="none"
												onClick={() => navigate("/rma")}
											>
												<Card.Header>
													<p>
														{" "}
														<Icon.UiRadios />
														<br /> Report RMA
													</p>
												</Card.Header>
											</Card>
										</Col>
									</>
								)}
							</>

							<hr />
							<></>
							{HasPermission(decodedToken, Permissions.PRODUCTION_USER, true) && (
								<Col
									className="tenant-cards"
									sm={6}
								>
									<Card
										border="none"
										onClick={() => navigate("/scanandmodel")}
									>
										<Card.Header>
											<p>
												{" "}
												<Icon.FileEarmarkBreakFill />
												<br /> Scan and model
											</p>
										</Card.Header>
									</Card>
								</Col>
							)}

							{HasPermission(decodedToken, Permissions.PRODUCTION_ADMIN, true) && (
								<Col
									className="tenant-cards"
									sm={6}
								>
									<Card
										border="none"
										onClick={() => navigate("/production")}
									>
										<Card.Header>
											<p>
												{" "}
												<Icon.CpuFill />
												<br />
												Confirm production
											</p>
										</Card.Header>
									</Card>
								</Col>
							)}
							{HasPermission(decodedToken, Permissions.PRODUCTION_USER, true) && (
								<Col
									className="tenant-cards"
									sm={6}
								>
									<Card
										border="none"
										onClick={() => navigate("/printbatches")}
									>
										<Card.Header>
											<p>
												{" "}
												<Icon.PrinterFill />
												<br />
												Print batches
											</p>
										</Card.Header>
									</Card>
								</Col>
							)}
							{HasPermission(decodedToken, Permissions.PRODUCTION_USER, true) && (
								<Col
									className="tenant-cards"
									sm={6}
								>
									<Card
										border="none"
										onClick={() => navigate("/shipping")}
									>
										<Card.Header>
											<p>
												{" "}
												<Icon.BoxSeamFill />
												<br />
												Shipping
											</p>
										</Card.Header>
									</Card>
								</Col>
							)}

							<hr />
							{(HasPermission(decodedToken, Permissions.ADMIN, true) ||
								HasPermission(decodedToken, Permissions.BRANDCONFIG_DESIGNER, true) ||
								HasPermission(decodedToken, Permissions.BRANDCONFIG_ADMIN, true)) && (
								<Col
									className="admin-card"
									sm={6}
								>
									<Card
										border="none"
										onClick={() => navigate("/adminpanel")}
									>
										<Card.Header>
											<p>
												{" "}
												<Icon.GearFill />
												<br /> Administrator panel
											</p>
										</Card.Header>
									</Card>
								</Col>
							)}

							<Col
								className="admin-card"
								sm={6}
							>
								<Card
									border="none"
									onClick={() => navigate("/search")}
								>
									<Card.Header>
										<p>
											{" "}
											<Icon.Search />
											<br /> Search
										</p>
									</Card.Header>
								</Card>
							</Col>
							<Col
								className="tenant-cards"
								sm={6}
							>
								<Card border="none">
									<a
										href="https://earsonly.nl/faq"
										target="_blank"
										rel="noreferrer"
									>
										<Card.Header>
											<p>
												{" "}
												<Icon.QuestionOctagonFill />
												<br /> FAQ/Troubleshooting
											</p>
										</Card.Header>
									</a>
								</Card>
							</Col>
							<Col
								className="tenant-cards"
								sm={6}
							>
								<Card border="none">
									<PDFDownloadLink
										document={<CreateBagCodeSheet barcodes={barcodesFromAPI} />}
										fileName={`BagCodeSheet.pdf`}
									>
										{/* @ts-ignore */}
										{({ blob, url, loading, error }) =>
											loading ? (
												<Card.Header>
													<p>
														{" "}
														<Spinner
															animation="border"
															color="red"
														/>
														<br /> Download Impression Codes	
													</p>
												</Card.Header>
											) : (
												<Card.Header>
													<p>
														{" "}
														<Icon.QrCode />
														<br /> Download Impression Codes
													</p>
												</Card.Header>
											)
										}
									</PDFDownloadLink>
								</Card>
							</Col>
						</Row>
					</Container>
				</>
			)}
			{!isAuthenticated && !isLoading && (
				<>
					<Container fluid>
						<Row
							className="loginscreen"
							xs={1}
							sm={2}
						>
							<Col className=" middle right-border bottom-border">
								<Image
									src="/EarsOnly_Logo_FullColour_Grey.svg"
									alt="EarsOnly Logo"
								></Image>
							</Col>
							<Col className="middle bottom-border">
								<h2>
									Not signed in <br /> <br /> <br />
									<Button
										size="lg"
										variant="primary"
										onClick={login}
									>
										Log in to the tenant portal
									</Button>
								</h2>
							</Col>
						</Row>
					</Container>
				</>
			)}
		</>
	);
}
