import * as React from "react";
import { useEffect, useState } from "react";
import { Badge, Button, Col, Form, ListGroup, Modal, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import * as Icon from "react-bootstrap-icons";
import { generateTag, uploadToHermesCache } from "../api/cdn_apimanager";
import { checkOutModelJob, confirmModels } from "../api/orderportal_apimanager";
import { CDN_response } from "../api/types/CDN_response";
import { Customer } from "../api/types/Customer";
import { ToBeModeledType } from "../api/types/ToBeModeledType";
import toastManager from "./toastmanager";

interface Props {
	scan: ToBeModeledType;
	tenant_id: string;
	customers: Customer[];
	// priority: React.JSX.Element
	token: string;
}

export default function ToBeModeled(props: Props) {
	const WARN_AFTER_AMOUNT_OF_DAYS = 2;
	const [left, setLeft] = useState<CDN_response>();
	const [right, setRight] = useState<CDN_response>();
	const [tree, setTree] = useState<CDN_response>();
	const [uploaded, setUploaded] = useState<boolean>();
	const [differentName, setDifferentName] = useState<boolean>(false);
	const [showDifferentNameWarning, setShowDifferentNameWarning] = useState<boolean>(false);
	const [warningThresholdPassedSinceCheckout, setWarningThresholdPassedSinceCheckout] = useState<boolean>(false);
	const [checkoutSince, setCheckoutSince] = useState<Date>(new Date(props.scan.checked_out_since));

	function handleUploadLeft(event: any) {
		upload(event.target.files[0], "left");
	}
	function handleUploadRight(event: any) {
		upload(event.target.files[0], "right");
	}
	function handleUploadModelingTree(event: any) {
		upload(event.target.files[0], "tree");
	}

	function upload(file: any, side: string) {
		uploadToHermesCache(props.tenant_id, props.scan.customer_id, file).then((response) => {
			if (side === "left") {
				setLeft(response);
				if (!props.scan.left_serial.includes(file.name.slice(0, -4))) {
					setDifferentName(true);
				}
			}
			if (side === "right") {
				setRight(response);
				if (!props.scan.right_serial.includes(file.name.slice(0, -4))) {
					setDifferentName(true);
				}
			}
			if (side === "tree") setTree(response);
		});
	}

	function handleConfirm(event: any) {
		if (left && right) {
			setShowDifferentNameWarning(false);
			const CDN_PATH = `${process.env.REACT_APP_CDN_BASEURL}/cache/hermes/${props.tenant_id}/${props.scan.customer_id}`;
			confirmModels(
				props.tenant_id,
				props.scan.customer_id,
				props.scan.id,
				`${CDN_PATH}/${left.data.document}`,
				`${CDN_PATH}/${right.data.document}`,
				tree ? `${CDN_PATH}/${tree.data.document}` : ""
			).then((response) => {
				if (response) setUploaded(true);
				else setUploaded(false);
			});
		}
	}

	function downloadFile(filepath: string) {
		var url = `${filepath}/download/?tag=${generateTag()}`;
		// eslint-disable-next-line no-restricted-globals
		location.href = url;
	}

	function checkOut() {
		checkOutModelJob(props.tenant_id, props.scan.customer_id, props.scan.id).then((response) => {});
	}

	const renderTooltip = (prop: any) => (
		<Tooltip
			id="tooltip"
			{...prop}
		>
			Checked out by: {props.scan.checked_out_by}
			{warningThresholdPassedSinceCheckout && (
				<>
					<br /> <br />
					It has been more than {WARN_AFTER_AMOUNT_OF_DAYS} day(s) since checkout
				</>
			)}
		</Tooltip>
	);

	useEffect(
		function checkIfWarningIsRequired() {
			var addDays = new Date(checkoutSince);
			addDays.setDate(addDays.getDate() + WARN_AFTER_AMOUNT_OF_DAYS);
			var now = new Date();
			if (now >= addDays) setWarningThresholdPassedSinceCheckout(true);
		},
		[checkoutSince]
	);

	return (
		<Form>
			<ListGroup.Item variant={props.scan.checked_out_by ? "secondary" : ""}>
				<Row>
					<Col
						sm={3}
						className="text-truncate"
					>
						<Icon.BuildingFill /> {props.customers.find((e) => e.id === props.scan.customer_id)?.name}
						<br />
						<Icon.TagFill />
						<> {props.scan.product_type ?? "Unknown"}</>
						<br />
						<Row>
							<Col
								className="copy fw-bold"
								onClick={() => {
									navigator.clipboard.writeText(`${props.scan.initials}`);
									toastManager("info", `Copied to clipboard!`, `Copied initials '${props.scan.initials}' to clipboard`);
								}}
							>
								<Icon.PencilFill /> {props.scan.initials ?? "none"}
							</Col>
							<Col
								onClick={() => {
									navigator.clipboard.writeText(`${props.scan.left_serial.slice(-1)}`);
									toastManager("info", `Copied to clipboard!`, `Copied revision code '${props.scan.left_serial.slice(-1)}' to clipboard`);
								}}
								className="copy fw-bold"
							>
								<Icon.RSquareFill />{props.scan.left_serial.slice(-1)}
							</Col>
						</Row>
					</Col>
					<Col sm={5}>
						{props.scan.impression_notes && props.scan.impression_notes !== "" && props.scan.impression_notes !== "No remarks." && (
							<div style={{ color: "red" }}>
								<Icon.PersonFillExclamation /> {props.scan.impression_notes}
							</div>
						)}
					</Col>
					<Col sm={4}>
						{props.scan.checked_out_by && (
							<OverlayTrigger
								placement="bottom"
								delay={{ show: 250, hide: 400 }}
								overlay={renderTooltip}
							>
								<h6 style={warningThresholdPassedSinceCheckout ? { color: "red", fontStyle: "italic" } : { color: "green" }}>
									<Icon.BoxArrowRight />
									{checkoutSince.toLocaleDateString()} {checkoutSince.toLocaleTimeString()}
								</h6>
							</OverlayTrigger>
						)}
					</Col>
				</Row>
				<br />
				<Row>
					<Row>
						<Col
							sm={3}
							className="download-button"
						>
							<Button
								title="download"
								role="button"
								className="btn btn-primary"
								onClick={() => {
									downloadFile(props.scan.left_scan);
									checkOut();
								}}
							>
								<small>
									<Icon.Download />
									{props.scan.left_serial}.stl
								</small>
							</Button>
						</Col>
						<Col sm={5}>
							<Form.Control
								disabled={uploaded}
								type="file"
								id={`${props.scan.id}-left`}
								accept=".stl"
								onChange={handleUploadLeft}
							/>
						</Col>
						<Col sm={2}>
							{" "}
							{left?.success === true && <h6 style={{ color: "green" }}>Successful upload</h6>}
							{left?.success === false && <h6 style={{ color: "red" }}>Upload failed</h6>}
						</Col>
					</Row>
					<Row>
						<Col
							sm={3}
							className="download-button"
						>
							<Button
								title="download"
								role="button"
								// className="btn btn-primaryn"
								onClick={() => {
									downloadFile(props.scan.right_scan);
									checkOut();
								}}
							>
								<small>
									<Icon.Download />
									{props.scan.right_serial}.stl
								</small>
							</Button>
						</Col>
						<Col sm={5}>
							<Form.Control
								disabled={uploaded}
								type="file"
								id={`${props.scan.id}-right`}
								accept=".stl"
								onChange={handleUploadRight}
							/>
						</Col>
						<Col sm={2}>
							{" "}
							{right?.success === true && <h6 style={{ color: "green" }}>Successful upload</h6>}
							{right?.success === false && <h6 style={{ color: "red" }}>Upload failed</h6>}
						</Col>
					</Row>
					<Row>
						<Col sm={3}>
							{props.scan.prev_modeling_tree_data ? (
								<Button
									title="download"
									role="button"
									className="btn btn-primary"
									onClick={() => {
										downloadFile(props.scan.prev_modeling_tree_data ?? "");
										checkOut();
									}}
								>
									<small>
										<Icon.Download />
										{props.scan.prev_modeling_tree_data.slice(0, -1)}.stl
									</small>
								</Button>
							) : (
								<Button
									title="download"
									role="button"
									disabled
									className="btn btn-primary"
								>
									<small>
										<Icon.Download />
										No tree available
									</small>
								</Button>
							)}
						</Col>

						<Col sm={5}>
							<Form.Control
								disabled={uploaded}
								type="file"
								id={`${props.scan.id}-tree`}
								accept=".stl"
								onChange={handleUploadModelingTree}
							/>
						</Col>
						<Col sm={2}>
							{" "}
							{tree?.success === true && <h6 style={{ color: "green" }}>Successful upload</h6>}
							{tree?.success === false && <h6 style={{ color: "red" }}>Upload failed</h6>}
						</Col>

						<Col className="right">
							{left && right && !uploaded && (
								<>
									{differentName ? (
										<Button
											variant="warning"
											onClick={() => setShowDifferentNameWarning(true)}
										>
											Confirm
										</Button>
									) : (
										<Button onClick={handleConfirm}>Confirm</Button>
									)}
								</>
							)}
							{uploaded === true && <h6 style={{ color: "green" }}>Successfully confirmed</h6>}
							{uploaded === false && <h6 style={{ color: "red" }}>Confirming failed</h6>}
						</Col>
					</Row>
				</Row>
				<Modal show={showDifferentNameWarning}>
					<Modal.Header className="middle">
						<Modal.Title>
							<Icon.ExclamationTriangleFill color="#DDB100" />
							Warning
							<Icon.ExclamationTriangleFill color="#DDB100" />
						</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						The name of the scan(s) does not correspond to the modeled filename. <br /> <br /> If this was not intentional you can cancel the confirmation and reupload the correct file{" "}
					</Modal.Body>
					<Modal.Footer>
						<Button
							variant="danger"
							onClick={() => setShowDifferentNameWarning(false)}
						>
							Cancel confirmation
						</Button>
						<Button
							variant="success"
							onClick={handleConfirm}
						>
							Accept and upload
						</Button>
					</Modal.Footer>
				</Modal>
			</ListGroup.Item>
		</Form>
	);
}
